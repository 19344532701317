<template>
    <div>
        <popup
                @close="$emit('abort')">
            <div slot="popupMainContent">
                <h1>{{ $t('createnew') }}</h1>
                <label>{{ $t('key') }}*</label>
                <input type="text"
                       :class="['form-text', $v.key.$dirty && $v.key.$error ? 'form-group--error' : '']"
                       v-model="key"
                       @input="delayTouch($v.key)"
                >
                <div class="form-error" v-if="$v.key.$dirty && !$v.key.minLength">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>
              <div class="form-error" v-if="$v.key.$dirty && !$v.key.alphaNum">{{ $t('errors.alphaNumOnly') }}</div>

              <div>
                    {{ $t('chooseMetaFieldType') }}<br>
                    <select v-model="type">
                        <option :key="index" v-for="(typeKey, typeType, index) in types" :value="typeKey">{{ $t(typeType) }}</option>
                    </select>
                </div>
            </div>
            <Button
                    slot="popupFooter"
                    class="mb-3 mt-3"
                    :deactivated="!enableSaving"
                    @click="save"
            >
                <icon v-if="enableSaving" type="save"/><icon v-else type="ban" />
                {{ $t('SAVE') }}
            </Button>
        </popup>
    </div>
</template>

<script>
    import Popup from "../Popup";
    import Button from "../forms/Button"
    import { required, minLength } from 'vuelidate/lib/validators';
    import {MetaFieldTypes} from '../../enum';
    import Icon from "../Icon";
    import {alphaNum} from '@/customValidators';
    import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";

    export default {
        name: "AddMetaField",
        components: {
            Popup,
            Button,
            Icon,
        },
        mixins: [VuelidateMixin],
        props: {
            metaSetId: {type: String, required: true},
        },
        data() {
            return {
                types: {},
                type: '',
                key: '',
                enableSaving: false,
            };
        },
        watch: {
            '$v.$invalid': function() {
                this.enableSaving = !this.$v.$invalid;
                this.$emit('enableSaving', !this.$v.$invalid);
            },
        },
        validations: {
            key: {
                required,
                alphaNum,
                minLength: minLength(3)
            },
        },
        beforeMount() {
            this.types = MetaFieldTypes;
            this.type = Object.values(MetaFieldTypes)[0];
        },
        methods: {
            save: async function () {
                let args = {
                    key: this.key,
                    type: this.type,
                    description: this.key,
                };
                if(this.$store.getters.getCurrentUserOrg) {
                    args.organizationId = this.$store.getters.getCurrentUserOrg;
                }
                await this.$store.dispatch('createMetaField', {
                    id: this.metaSetId,
                    args: args,
                }).then(() => {
                  this.$emit('save');
                })
            },

        },
    }
</script>

<style scoped>

</style>