<template>
    <div>
      <popup
          v-if="showQuestionPopup"
          @close="() => {showQuestionPopup = false;}"
      >
        <delete-prompt slot="popupMainContent"
                       @abort="showQuestionPopup = false"
                       @confirm="() => {
                         deleteMetaField();
                         showQuestionPopup = false;
                       }"
        />
      </popup>
        <div class="lighter">ID: {{ metaField.id }}</div>
        <table class="mb-2">
            <meta-meta-field-row
                    :key="index" v-for="(field, index) in editableFields" :class="[index%2 === 0 ? 'even' : 'odd']"
                    :field="field"
                    :meta-field="metaField"
                    :meta-set-id="metaSetId"
                    :editable="!field.alwaysLocked && ((!hasNoMetaValueData && !field.lockWheninUse) || hasNoMetaValueData)"
            />
        </table>
        <div
                v-if="hasNoMetaValueData"
                class="settings-button d-inline-block"
                type="delete-button"
                @click="showQuestionPopup = true"
                icon="trash">
            {{ $t('delete') }}
        </div>
    </div>
</template>

<script>
    import MetaMetaFieldRow from "@/components/widgets/meta/AdminMetaFieldRow";
    import Popup from "../../Popup";
    import DeletePrompt from "../../forms/DeletePrompt";
    export default {
        name: "MetaFieldEdit",
        components: {
          MetaMetaFieldRow,
          Popup,
          DeletePrompt
        },
        props: {
            metaSetId: {type: String, required: true},
            editableFields: {type: Array, required: true},
            metaField: {type: Object, required: true},
        },
        data() {
            return {
                hasNoMetaValueData: true,
                showQuestionPopup: false
            };
        },
        beforeMount() {
            this.$store.dispatch('getMetaValues', {
                id: this.metaSetId,
                cid: this.metaField.id,
                fields: 'id',
                limit: 1
            }).then(data => {
                this.hasNoMetaValueData = (data.length === 0) || !data;
            });
        },
        methods: {
            deleteMetaField() {
                this.$store.dispatch('deleteMetaField', {
                    id: this.metaSetId,
                    listName: this.metaSetId,
                    cid: this.metaField.id,
                    args: [this.metaField.id],
                });
            },
        },
    }
</script>

