<template>
    <div>
        <admin-meta-field-edit :key="index"
                               v-for="(metaField, index) in metaFields"
                               :editable-fields="editableFields"
                               :meta-field="metaField"
                               :meta-set-id="metaSetId"
                               class="mb-4"
        />
        <Button
                class="mt-2 mb-4"
                type="action-button"
                @click="showMetaFieldAddForm"
                icon="plus">
            {{ $t('createNewMetaField') }}
        </Button>
        <add-meta-field
                :meta-set-id="metaSetId"
                v-if="addingMetaField"
                @save="addMetaField"
                @abort="() => {addingMetaField = false;}"
        />
    </div>
</template>

<script>
    import AdminMetaFieldEdit from "./AdminMetaFieldEdit";
    import Button from "../../forms/Button";
    import { mapState } from 'vuex';
    import AddMetaField from "@/components/forms/AddMetaField";
    export default {
        name: "MetaFieldEdit",
        components: {
            AdminMetaFieldEdit,
            Button,
            AddMetaField
        },
        props: {
            metaSetId: {type: String, required: true}
        },
        data() {
            return {
                editableFields: [
                    {
                        name: 'key',
                        type: 'string',
                        lockWheninUse: true,
                        alwaysLocked: true,
                    },
                    {
                        name: 'description',
                        type: 'string',
                        lockWheninUse: false,
                    },
                    {
                        name: 'type',
                        type: 'string',
                        lockWheninUse: true,
                        alwaysLocked: true,
                    },
                    {
                        name: 'config',
                        type: 'json',
                        lockWheninUse: true,
                    },
                    {
                        name: 'position',
                        type: 'number',
                        lockWheninUse: false,
                    },
                    {
                        name: 'defaultValue',
                        type: 'string',
                        lockWheninUse: false,
                    },
                    {
                        name: 'required',
                        type: 'boolean',
                        lockWheninUse: false,
                    },
                ],
                addingMetaField: false,
                metaFields: null,
            };
        },
        computed: mapState({metaFieldLists: state => state.metafield.metaFieldLists,}),
        watch: {
          metaFieldLists: {
            deep: true,
            handler() {
                this.metaFields = this.$store.getters.getMetaFieldsByListName(this.metaSetId)
            }
          }
        },
        beforeMount() {
           this.load();
        },
        methods: {
            load() {
                this.$store.dispatch('loadMetaFields', {
                    keep: {
                        id: this.metaSetId,
                        sort: '+position'
                    },
                    listName: this.metaSetId,
                });
            },
            showMetaFieldAddForm: function(){
                this.addingMetaField = true;
            },
          addMetaField: function() {
                this.load();
                this.addingMetaField = false;
            },
        },
    }
</script>

<style scoped>

</style>